<template>
  <b-form-group
    label="Bağlantı"
    label-for="url"
  >
    <b-form-input
      id="url"
      v-model="dataItem.url"
      placeholder="Bağlantı"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue'

export default {
  name: 'UrlText',
  components: {
    BFormGroup,
    BFormInput,
  },
  computed: {
    dataItem() {
      return this.$store.getters['events/dataItem']
    },
  },
}
</script>
