<template>
  <div>
    <b-card>
      <title-text />
      <award-text />
      <end-date />
      <persons />
      <content-text />
      <rules-text />
      <winner-date />
      <winners-text />
      <ordering-text />
      <url-text />
    </b-card>
    <image-card />
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import TitleText from '@/views/Admin/Events/elements/TitleText.vue'
import WinnerDate from '@/views/Admin/Events/elements/aDate.vue'
import EndDate from '@/views/Admin/Events/elements/eDate.vue'
import AwardText from '@/views/Admin/Events/elements/AwardText.vue'
import Persons from '@/views/Admin/Events/elements/Persons.vue'
import ContentText from '@/views/Admin/Events/elements/ContentText.vue'
import RulesText from '@/views/Admin/Events/elements/RulesText.vue'
import WinnersText from '@/views/Admin/Events/elements/WinnersText.vue'
import OrderingText from '@/views/Admin/Events/elements/OrderingText.vue'
import ImageCard from '@/views/Admin/Events/elements/ImageCard.vue'
import UrlText from '@/views/Admin/Events/elements/UrlText.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    TitleText,
    ContentText,
    RulesText,
    WinnersText,
    OrderingText,
    EndDate,
    AwardText,
    Persons,
    ImageCard,
    WinnerDate,
    UrlText,
  },
}
</script>
