<template>
  <b-form-group
    label="İçerik"
    label-for="content"
  >
    <validation-provider
      #default="{ errors }"
      name="İçerik"
      rules="required"
    >
      <quill-editor
        v-model="dataItem.content"
        :options="editorOption"
        class="bg-white text-black-50"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
// import EditorToolbar from '@/layouts/components/common/EditorToolbar.vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ direction: 'rtl' }], // text direction

  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],

  ['clean'], // remove formatting button
]

export default {
  name: 'Content',
  components: {
    BFormGroup,
    quillEditor,
    ValidationProvider,
    // EditorToolbar,
  },
  data() {
    return {
      required,
      editorOption: {
        modules: {
          toolbar: toolbarOptions,
        },
        theme: 'snow',
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['events/dataItem']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
