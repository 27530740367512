<template>
  <b-form-group
    label="Kazananlar Tarihi"
    label-for="adate"
  >
    <b-form-datepicker
      id="adate"
      v-model="itemData.adate"
      placeholder="Kazananlar Tarihi"
      v-bind="{labelNoDateSelected: 'Bitiş Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
      locale="tr"
      start-weekday="1"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormDatepicker } from 'bootstrap-vue'

export default {
  name: 'Adate',
  components: {
    BFormGroup,
    BFormDatepicker,
  },
  computed: {
    itemData() {
      return this.$store.getters['events/dataItem']
    },
  },
}
</script>
